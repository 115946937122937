import React, { useEffect, useState } from "react";
/* Importing styles */
import "../styles/gallery.css";

import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import Image from "./misc/Image";
import ReactPlayer from "react-player";

export default function Gallery() {
	const [imgs, setImgs] = useState([]);
	const gallery = useSelector((state) => state.reducer.data.website.gallery);
	const videos = useSelector((state) => state.reducer.data.website.youtube);
	const [_vids, set_vids] = useState([]);
	const loading = useSelector((state) => state.reducer.meta.loading);

	useEffect(() => {
		if (loading) return;

		var _buffer_imgs = [];

		for (let img in gallery) {
			_buffer_imgs.push({ ...gallery[img] });
		}
		setImgs(_buffer_imgs);

		var _buffer_vids = [];

		for (let vid in videos) {
			_buffer_vids.push({ ...videos[vid] });
		}
		set_vids(_buffer_vids);

		// eslint-disable-next-line
	}, [loading]);

	return (
		<section className="gallery" id="gallery">
			<div className="gallery-head">
				<h1>Gallery</h1>
			</div>
			<div className="gallery-content">
				{loading && (
					<div className="loading">
						<CircularProgress thickness={4} sx={{ color: "gold" }} />
					</div>
				)}
				{imgs
					.sort((a, b) => {
						if (a.timestamp > b.timestamp) return 1;
						return -1;
					})
					.map((item, i) => (
						<Image fullSize data={item} key={i} alt="" />
					))}
			</div>
			<div className="gallery-content">
				{_vids
					.sort((a, b) => {
						if (a.timestamp > b.timestamp) return -1;
						return 1;
					})
					.map((item, i) => (
						<ReactPlayer
							controls
							url={item.url}
							key={i}
							width={500}
							height={350}
						/>
					))}
			</div>
		</section>
	);
}
