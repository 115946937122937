import { Close, Delete } from "@mui/icons-material";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Icon,
	IconButton,
	Tooltip,
} from "@mui/material";
import { deleteObject, ref } from "firebase/storage";
import React, { useState } from "react";
import { storage } from "../../shared/data/firebase";
import Neon from "../../shared/data/neon";
import { FilledButton, TextButton } from "../../shared/utils/components";

export default function Image({ data, fullSize = false }) {
	const [imgLoading, setImgLoading] = useState(false);
	const [open, setOpen] = useState(false);

	return (
		<div className={`gallery-img ${fullSize && "gallery-fullsize"}`}>
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/atm-website-73401.appspot.com/o/gallery%2F${data.url}?alt=media&token=1ea31a7d-2454-41c5-b8ce-687802541085`}
				alt=""
				height={fullSize ? "300" : "130"}
				width={fullSize ? "300" : "270"}
				loading="eager"
				title={data.title}
			/>
			<p>{data.title}</p>

			{!fullSize && (
				<Tooltip title="Delete Image">
					<IconButton onClick={() => setOpen(true)}>
						<Icon>
							<Delete htmlColor="tomato"></Delete>
						</Icon>
					</IconButton>
				</Tooltip>
			)}

			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle sx={{ color: "tomato" }}>
					Delete Image
					<Icon>
						<Delete sx={{ color: "tomato" }} />
					</Icon>
				</DialogTitle>
				<DialogContent>
					{imgLoading ? (
						<div className="loading">
							<div className="loading">
								<CircularProgress thickness={4} sx={{ color: "gold" }} />
							</div>
						</div>
					) : (
						<DialogContentText>
							Are you sure you want to delete this image permanently ?
						</DialogContentText>
					)}

					<DialogActions>
						<TextButton
							onClick={() => {
								setImgLoading(true);
								const del_ref = ref(storage, `gallery/${data.url}`);
								deleteObject(del_ref);

								Neon.delete(`/website/gallery/${data.url}`).then(() => {
									setImgLoading(false);
									window.location.reload();
								});
							}}
							disabled={imgLoading}
							startIcon={<Delete />}
						>
							Delete
						</TextButton>
						<FilledButton
							startIcon={<Close />}
							disabled={imgLoading}
							onClick={() => setOpen(false)}
						>
							Cancel
						</FilledButton>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</div>
	);
}
