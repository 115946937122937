// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyB1-mC9Wd6bWF23ud7ln7gZot3KMq8bya8",
	authDomain: "atm-website-73401.firebaseapp.com",
	projectId: "atm-website-73401",
	storageBucket: "atm-website-73401.appspot.com",
	messagingSenderId: "770019820950",
	appId: "1:770019820950:web:42e1f0a65d654f8826c9b7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
