import React, { useEffect, useState } from "react";

import "../styles/onlinedonation.css";
import Neon from "../shared/data/neon";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function Success() {
	const { id } = useParams();

	const [payment, setPayment] = useState({});
	const [order, setOrder] = useState({});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		Neon.get_payment(id)
			.then((res) => {
				setPayment(res.data);
				Neon.get_order(res.data.order_id).then((res) => {
					setOrder(res.data);
					setLoading(false);
				});
			})
			.catch((e) => {
				return (window.location.href = "/error");
			});

		// eslint-disable-next-line
	}, []);

	return (
		<div className="online-donation success">
			<div className="home-banner">
				<img
					src="/assets/images/atm_banner.png"
					title="ATM Global Group"
					width="800"
					loading="eager"
					height="180"
					alt=""
				/>
			</div>

			<div className="success-box">
				{loading ? (
					<div
						className="loading-box"
						style={{ width: "100%", display: "flex", justifyContent: "center" }}
					>
						<CircularProgress />
					</div>
				) : (
					<>
						<div className="success-img">
							<img src="/assets/images/success.gif" alt="" />
						</div>
						<h2>Payment Successful</h2>
						<p style={{ fontSize: 17 }}>
							Your Payment has been processed successfully.
						</p>
						<p style={{ fontSize: 17 }}>
							<strong>Transaction Details: </strong>
						</p>
						<hr />
						<p>
							Amount Paid{/* * */}: Rs.{" "}
							{/* {payment.amount &&
								Math.round(payment.amount / 100 - payment.fee / 100)} */}
							{payment.amount / 100}
						</p>
						<p>Transaction Ref No. : {payment && payment.id}</p>
						<p>Receipt No. : {order && order.receipt}</p>
						<p>
							Transaction Date & Time:{" "}
							{payment &&
								new Date(payment.created_at * 1000).toLocaleString("gu")}
						</p>
						<br />
						{/* <p>
							*Receipt will be generated for amount after deducting transaction
							fees.
						</p> */}
						<br />
						<div className="success-btns">
							<a
								href={`${process.env.REACT_APP_PYT_URL}/public/receipt/${order.receipt}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								Download Receipt
							</a>
							<a href="/online-donation">Make another Donation</a>{" "}
							<a href="/">Go to Home Page</a>{" "}
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default Success;
