import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { configureStore } from "@reduxjs/toolkit";
import RootReducer, {
	fetchData,
	fetch_announcements,
} from "./components/shared/data/redux/reducers";
import Neon from "./components/shared/data/neon";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("app"));

const store = configureStore({
	reducer: RootReducer,
	devTools: false,
});

window.store = store;

Neon.fetch("/website").then((res) => {
	store.dispatch(fetchData(res));
});

Neon.fetch("/announcements").then((res) => {
	store.dispatch(fetch_announcements(res));
});

root.render(
	<Provider store={store}>
		<HelmetProvider>
			<Router>
				<App />
			</Router>
		</HelmetProvider>
	</Provider>
);
