import React from "react";
import { useParams } from "react-router-dom";

function Unverified() {
	const { id } = useParams();
	return (
		<div className="online-donation success">
			<div className="home-banner">
				<img
					src="/assets/images/atm_banner.png"
					title="ATM Global Group"
					width="800"
					loading="eager"
					height="180"
					alt=""
				/>
			</div>

			<div className="success-box">
				<div className="success-img">
					<img src="/assets/images/fail.gif" alt="" />
				</div>
				<h2>Payment Unverified</h2>
				<p style={{ fontSize: 17 }}>Your transaction could not be verified.</p>
				<p>
					The transaction reference number seems to be invalid. Kindly contact
					our co-ordinators to confirm if your payment has been processed.
				</p>

				<p>Transaction Ref. No: {id}</p>

				<br />
				<div className="success-btns">
					<a href="/">Go to Home Page</a>{" "}
				</div>
			</div>
		</div>
	);
}

export default Unverified;
