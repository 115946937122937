import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "./misc/Card";

/* Importing styles */
import "../styles/announcements.css";

export default function Announcements() {
	const data = useSelector((state) => state.reducer.data.announcements);
	const loading = useSelector((state) => state.reducer.meta.loading);
	const [_data, set_data] = useState([]);

	useEffect(() => {
		if (loading) return;

		var list = [];
		for (let id in data) {
			list.push({ id, ...data[id] });
		}
		set_data(list);

		// eslint-disable-next-line
	}, [loading]);

	return (
		<section className="announcements" id="announcements">
			<div className="ann-blob"></div>
			<div className="ann-hero">
				<div className="ann-left">
					<div className="ann-hero-head">
						<img
							width="200"
							loading="lazy"
							height="150"
							title="ATM Global Group"
							src="./assets/icons/announcements_icon.svg"
							alt=""
						/>
						<h1>ANNOUNCEMENTS</h1>
					</div>
					<div className="ann-content">
						{_data.map((d, i) => (
							<Card key={i} data={d} isOk={false} />
						))}
					</div>
				</div>
				<div className="ann-right">
					<img
						width="500"
						loading="lazy"
						height="250"
						title="ATM Global Group"
						src="./assets/images/periyava_3.webp"
						alt=""
					/>
				</div>
			</div>
		</section>
	);
}
