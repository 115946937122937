import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../shared/utils/Navbar";
import Gallery from "../Gallery";

export default function GalleryRoute() {
	return (
		<div>
			<>
				<Helmet>
					<title>Gallery</title>
					<meta
						name="description"
						content="View Images and Videos of Arul Tharum Mahaperiyava Global Group"
					/>
					<link rel="canonical" href="/gallery" />
				</Helmet>
				<Navbar active="gallery" />
				<Gallery />
			</>
		</div>
	);
}
