import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../shared/utils/Navbar";
import Announcements from "../Announcements";

export default function AnnouncementsRoute() {
	return (
		<div>
			<>
				<Helmet>
					<title>Announcements</title>
					<meta
						name="description"
						content="View the latest announcements from Arul Tharum Mahaperiyava Global Group"
					/>
					<link rel="canonical" href="/announcements" />
				</Helmet>
				<Navbar active="announcements" />
				<Announcements />
			</>
		</div>
	);
}
