import { Button, Tab, Tabs, TextField, colors } from "@mui/material";
import { styled } from "@mui/material/styles";

const PrimaryButton = styled(Button)(() => ({
	minWidth: 150,
	width: "auto",
	backgroundColor: "transparent",
	border: "2px solid #FBF08F",
	borderRadius: 5,
	color: "#FBF08F",
}));

const FilledButton = styled(Button)(() => ({
	minWidth: 150,
	minHeight: 45,
	fontSize: 15,
	width: "auto",
	background: "#fc8200",
	border: "none",
	borderRadius: 5,
	color: "white",
	":hover": {
		background: "#bf6504",
	},
	fontWeight: "bold",
}));

const TextButton = styled(Button)(() => ({
	minWidth: 150,
	minHeight: 45,
	fontSize: 15,
	width: "auto",
	background: "transparent",
	border: "none",
	color: "#F2C31A",
}));

const TextInput = styled(TextField)(() => ({
	minWidth: 300,
	border: "none",
	outline: "none",
	borderRadius: 4,
}));

const StyledTab = styled(Tab)(() => ({
	color: "orange",
	"&.Mui-selected": {
		color: "white",
		fontWeight: "bold",
		background: colors.deepOrange[300],
		borderRadius: 5,
	},
}));

const StyledTabs = styled((props) => (
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
))({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		height: 4,
		backgroundColor: "transparent",
	},
	"& .MuiTabs-indicatorSpan": {
		maxWidth: 80,
		width: "100%",
		backgroundColor: colors.orange[100],
	},
});

export {
	PrimaryButton,
	TextInput,
	FilledButton,
	TextButton,
	StyledTab,
	StyledTabs,
};
