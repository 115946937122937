import { KeyboardBackspace } from "@mui/icons-material";
import React from "react";
import { PrimaryButton } from "../shared/utils/components";

/* Import CSS */
import "../styles/home.css";
import Navbar from "../shared/utils/Navbar";
import { Link } from "react-router-dom";
import countapi from "countapi-js";
import { Helmet } from "react-helmet-async";

countapi.update("atm_website.com", "501a839a-e11c-4cec-827d-63ac0ec3deec", +1);

export default function Home() {
	return (
		<div className="hom-container">
			<Helmet>
				<title>Arul Tharum Maha Periyava Global Group</title>
				<meta
					name="description"
					content="Arul Tharum Maha Periyava Global Group HH Sri Bala Periyava blessed ATM Group with Anugraha Baashyam on the inaugural day of 05th June 2021."
				/>
				<link rel="canonical" href="/" />
			</Helmet>
			<section id="home" className="home">
				<div className="home-banner">
					<img
						src="/assets/images/atm_banner.png"
						title="ATM Global Group"
						width="800"
						loading="eager"
						height="180"
						alt=""
					/>
				</div>
				<div className="home-hero">
					<Navbar no_top />

					<div className="home-left">
						<div className="home-hero-text">
							<h1 style={{ fontSize: 30 }}>
								ARUL THARUM MAHAPERIYAVA CHARITABLE TRUST (ATMC TRUST)
							</h1>
							<h1 style={{ fontSize: 24 }}>
								ARUL THARUM MAHAPERIYAVA GLOBAL GROUP (ATM)
							</h1>
							<p>
								சிந்தயில் தோன்றி, வாக்கில் நின்று, நம் அனைவரையும்
								செயல்படவைக்கும் அருள் தரும் மஹாபெரியவா!
							</p>
						</div>
						<div className="home-btn">
							<PrimaryButton startIcon={<KeyboardBackspace color="#FBF08F" />}>
								<Link to="/about-atm">
									<span style={{ color: "#FBF08F" }}>Learn ATM</span>
								</Link>
							</PrimaryButton>
						</div>
					</div>
					<div className="home-right">
						<Link to="about">
							<img
								src="/assets/images/periyava_1 1.png"
								alt=""
								width="500"
								loading="eager"
								title="ATM Global Group"
								height="250"
								className="home-right-img"
							></img>
						</Link>
					</div>
				</div>
			</section>
		</div>
	);
}
