import React from "react";
import { Link } from "react-router-dom";

/* Importing Styles */
import "./navbar.css";
export default function Navbar({ no_top = false, active = "home" }) {
	return (
		<div className={`nav-bar ${!no_top && "nav-top"}`}>
			<ul className="nav-items">
				<Link to="/">
					<li className={active === "home" ? "nav-active" : undefined}>Home</li>
				</Link>
				<Link to={"/about-atm"}>
					<li className={active === "about" ? "nav-active" : undefined}>
						About ATM
					</li>
				</Link>
				<Link to="/activities">
					<li className={active === "activities" ? "nav-active" : undefined}>
						Our Activities
					</li>
				</Link>
				<Link to="/gallery">
					<li className={active === "gallery" ? "nav-active" : undefined}>
						Gallery
					</li>
				</Link>
				<Link to="/announcements">
					<li className={active === "announcements" ? "nav-active" : undefined}>
						Announcements
					</li>
				</Link>
				<Link to="/join">
					<li className={active === "join" ? "nav-active" : undefined}>
						Join us
					</li>
				</Link>
				<Link to="/online-donation">
					<li className={active === "join" ? "nav-active" : undefined}>
						Online Donation
					</li>
				</Link>
				<a
					href="https://members.arultharummahaperiyava.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					<li>Login / Register</li>
				</a>
			</ul>
		</div>
	);
}
