import React from "react";

function Privacy() {
	return (
		<div style={{ padding: 20 }}>
			<h1>Privacy Policy</h1>
			<p>Last updated: May 25, 2024</p>
			<a href="/">Go to Home Page</a>

			<p>
				This Privacy Policy describes Our policies and procedures on the
				collection, use and disclosure of Your information when You use the
				Website or mobile application (hereinafter referred to as "app")
			</p>
			<p>
				We use Your Personal data to provide and improve the Website or app. By
				using the Website or app, You agree to the collection and use of
				information in accordance with this Privacy Policy. If you do not agree
				please do not use or access our Website. By mere use of the Website, you
				expressly consent to our use and disclosure of your personal information
				in accordance with this Privacy Policy. This Privacy Policy is
				incorporated into and subject to the Terms of Use.
			</p>
			<h2>Interpretation and Definitions</h2>
			<h3>Interpretation</h3>
			<p>
				The words of which the initial letter is capitalized have meanings
				defined under the following conditions. The following definitions shall
				have the same meaning regardless of whether they appear in singular or
				in plural.
			</p>
			<h3>Definitions</h3>
			<p>For the purposes of this Privacy Policy:</p>
			<ul>
				<li>
					<p>
						<strong>Account</strong> means a unique account created for You to
						access our Website or app or parts of our Website or app.
					</p>
				</li>

				<li>
					<p>
						<strong>Application</strong> refers to Arul Tharum Mahaperiyava
						Global Group, the website and mobile app provided by the trust.
					</p>
				</li>
				<li>
					<p>
						<strong>Trust</strong> (referred to as either &quot;the ATMC
						trust&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
						this Agreement) refers to Arul Tharum Mahaperiyava Charitable Trust
						(ATMC Trust).
					</p>
				</li>
				<li>
					<p>
						<strong>Cookies</strong> are small files that are placed on Your
						computer, mobile device or any other device by a website, containing
						the details of Your browsing history on that website among its many
						uses.
					</p>
				</li>
				<li>
					<p>
						<strong>Country</strong> refers to: India
					</p>
				</li>
				<li>
					<p>
						<strong>Device</strong> means any device that can access the Website
						or app such as a computer, a cellphone or a digital tablet.
					</p>
				</li>
				<li>
					<p>
						<strong>Personal Data</strong> is any information that relates to an
						identified or identifiable individual.
					</p>
				</li>
				<li>
					<p>
						<strong>Website or app</strong> refers to the website hosted at
						https://arultharummahaperiyava.com/ and its underlying web
						application that can be installed in a device.
					</p>
				</li>
				<li>
					<p>
						<strong>Website or app Provider</strong> means any natural or legal
						person who processes the data on behalf of the trust. It refers to
						third-party companies or individuals employed by the trust to
						facilitate the Website or app, to provide the Website or app on
						behalf of the trust, to perform Website or apps related to the
						Website or app or to assist the trust in analyzing how the Website
						or app is used.
					</p>
				</li>
				<li>
					<p>
						<strong>Usage Data</strong> refers to data collected automatically,
						either generated by the use of the Website or app or from the
						Website or app infrastructure itself.
					</p>
				</li>

				<li>
					<p>
						<strong>You</strong> means the individual accessing or using the
						Website or app, or trust, or other legal entity on behalf of which
						such individual is accessing or using the Website or app, as
						applicable.
					</p>
				</li>
			</ul>
			<h2>
				Collection of Personally Identifiable Information and other Information
			</h2>
			<h4>Demographic / Profile data / Your information</h4>
			<p>
				While using Our Website or app, We may ask You to provide Us with
				certain personal information that can be stored, used to contact or
				identify You. Such information may include, but is not limited to:
			</p>
			<ul>
				<li>
					<p>Email address</p>
				</li>
				<li>
					<p>First name and last name</p>
				</li>
				<li>
					<p>Phone number</p>
				</li>
				<li>
					<p>Gender</p>
				</li>
				<li>
					<p>Permanent Account Number (PAN)</p>
				</li>
				<li>
					<p>Address, State, Province, ZIP/Postal code, City</p>
				</li>
				<li>
					<p>Usage Data</p>
				</li>
			</ul>

			<p>
				When you make an online donation using our website or mobile app, you
				will be required to provide certain information in addition to the
				Personal Information noted above. Such information may include a debit
				card number, credit card number, and similar information (collectively,
				the “Billing Information”). All transactions are processed by our
				third-party payment vendors. We do not collect, store, or maintain any
				Billing Information. Instead, it is collected and processed through
				these third-party vendors pursuant to the terms and conditions of their
				privacy policies and terms of use.
			</p>

			<p>
				In general, you can browse the Website without telling us who you are or
				revealing any personal information about yourself. Once you give us your
				personal information, you are not anonymous to us. Where possible, we
				indicate which fields are required and which fields are optional. You
				always have the option to not provide information by choosing not to use
				a particular service or feature on the Website.{" "}
			</p>

			<h4>Usage Data</h4>
			<p>
				Usage Data is collected automatically when using the Website or app.
			</p>
			<p>
				Usage Data may include information such as Your Device's Internet
				Protocol address (e.g. IP address), browser type, browser version, the
				pages of our Website or app that You visit, the time and date of Your
				visit, the time spent on those pages, unique device identifiers and
				other diagnostic data.
			</p>
			<p>
				When You access the Website or app by or through a mobile device, We may
				collect certain information automatically, including, but not limited
				to, the type of mobile device You use, Your mobile device unique ID, the
				IP address of Your mobile device, Your mobile operating system, the type
				of mobile Internet browser You use, unique device identifiers and other
				diagnostic data.
			</p>
			<p>
				We may also collect information that Your browser sends whenever You
				visit our Website or app or when You access the Website or app by or
				through a mobile device.
			</p>
			<h4>Tracking Technologies and Cookies</h4>
			<p>
				We may use Cookies and similar tracking technologies to track the
				activity on Our Website or app and store certain information. Tracking
				technologies used are beacons, tags, and scripts to collect and track
				information and to improve and analyze Our Website or app. The
				technologies We use may include:
			</p>
			<ul>
				<li>
					<strong>Cookies or Browser Cookies.</strong> A cookie is a small file
					placed on Your Device. By using our website or app, You agree to us
					using such cookies as may be necessary or as may be decided at our
					discretion to be necessary, for providing You, access to our website
					or app.
				</li>
				<li>
					<strong>Web Beacons.</strong> Certain sections of our Website or app
					and our emails may contain small electronic files known as web beacons
					(also referred to as clear gifs, pixel tags, and single-pixel gifs)
					that permit the trust, to count users who have visited those pages or
					opened an email and for other related website statistics (for example,
					recording the popularity of a certain section and verifying system and
					server integrity).
				</li>
			</ul>
			<p>
				Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
				Persistent Cookies remain on Your personal computer or mobile device
				when even You go offline, while Session Cookies are deleted as soon as
				You close Your web browser.
			</p>
			<p>
				We may use both Session and Persistent Cookies in our website or app:
			</p>

			<h3>Use of Your Personal Data</h3>
			<p>The trust may use Personal Data for the following purposes:</p>
			<ul>
				<li>
					<p>
						<strong>To provide and maintain our Website or app</strong>,
						including to monitor the usage of our Website or app.
					</p>
				</li>
				<li>
					<p>
						<strong>To manage Your Account:</strong> to manage Your registration
						as a user of the Website or app. The Personal Data You provide can
						give You access to functionalities of the Website or app that are
						available to You as a registered user.
					</p>
				</li>

				<li>
					<p>
						<strong>To contact You:</strong> To contact You by email, telephone
						calls, SMS, Whatsapp or other forms of electronic communication,
						mobile application's push notifications regarding updates or
						informative communications related to the functionalities, including
						the security updates, when necessary or reasonable for their
						implementation.
					</p>
				</li>

				<li>
					<p>
						<strong>To manage Your requests:</strong> To attend and manage Your
						requests to Us.
					</p>
				</li>

				<li>
					<p>
						<strong>For other purposes</strong>: We may use Your information for
						other purposes, such as data analysis, identifying usage trends,
						determining the effectiveness of our activities and to evaluate and
						improve our Website or app.
					</p>
				</li>
			</ul>
			<p>We may share Your personal information in the following situations:</p>
			<ul>
				<li>
					<strong>With Website or app Providers:</strong> We may share Your
					personal information with Website or app Providers to monitor and
					analyze the use of our Website or app, to contact You.
				</li>

				<li>
					We may disclose personal information if required to do so by law or in
					the good faith belief that such disclosure is reasonably necessary to
					respond to subpoenas, court orders, or other legal process. We may
					disclose personal information to law enforcement offices, third party
					rights owners, or others in the good faith belief that such disclosure
					is reasonably necessary to: enforce our Terms or Privacy Policy;
					respond to claims that an advertisement, posting or other content
					violates the rights of a third party; or protect the rights, property
					or personal safety of our users or the general public.
				</li>
			</ul>

			<p>
				Unconditionally, You give Your consent to Us to share Your personal
				information with any third party, wherever third party Kaingaryam(s) are
				involved. We are not responsible and / or liable in any manner
				whatsoever for any usage of Your data by such third parties
			</p>

			<h3>Retention of Your Personal Data</h3>
			<p>
				The trust will retain Your Personal Data only for as long as is
				necessary for the purposes set out in this Privacy Policy and to comply
				all legal obligations.
			</p>
			<p>
				The trust will also retain Usage Data for internal analysis purposes.
				Usage Data is generally retained for a shorter period of time, except
				when this data is used to strengthen the security or to improve the
				functionality of Our Website or app, or We are legally obligated to
				retain this data for a longer period of time.
			</p>

			<p>
				We take reasonable steps to protect the Personal Information and the
				Other Information from loss, misuse, and unauthorized access,
				disclosure, alteration, or destruction. Please understand, however, that
				no security system is impenetrable. We cannot guarantee the security of
				our databases, nor can we guarantee that the information you supply will
				not be intercepted while being transmitted to and from us over the
				Internet.
			</p>

			<h3>Transfer of Your Personal Data</h3>
			<p>
				Your information, including Personal Data, is processed at the trust's
				operating offices and in any other places where the parties involved in
				the processing are located. It means that this information may be
				transferred to — and maintained on — computers located outside of Your
				state, province, country or other governmental jurisdiction where the
				data protection laws may differ than those from Your jurisdiction.
			</p>
			<p>
				Your consent to this Privacy Policy followed by Your submission of such
				information represents Your agreement to that transfer.
			</p>

			<h3>Delete Your Personal Data</h3>
			<p>You may request that We delete your Personal Data with us.</p>
			<p>
				Our Website or app does not give You the ability to delete information
				about You from within the Website or app.
			</p>
			<p>
				You may update, or amend Your information at any time by signing in to
				Your Account, if you have one, and visiting the profile settings section
				that allows you to manage Your personal information. You may also
				contact Us to request access to, or correct, any personal information
				that You have provided to Us.
			</p>

			<h3>Security of Your Personal Data</h3>
			<p>
				The security of Your Personal Data is important to Us, but remember that
				no method of transmission over the Internet, or method of electronic
				storage is completely secure. While We strive to use commercially
				acceptable and reasonable means to protect Your Personal Data, We cannot
				guarantee its absolute security.
			</p>
			<h2>Children's Privacy</h2>
			<p>
				Our Website or app does not address anyone under the age of 18. We do
				not knowingly collect personally identifiable information from anyone
				under the age of 18.
			</p>

			<h2>Links to Other Websites</h2>
			<p>
				Our Website or app may contain links to other websites that are not
				operated by Us. If You click on a third party link, You will be directed
				to that third party's site. We strongly advise You to review the Privacy
				Policy of every site You visit.
			</p>
			<p>
				We have no control over and assume no responsibility for the content,
				privacy policies or practices of any third party sites or Website or
				apps.
			</p>
			<h2>Changes to this Privacy Policy</h2>
			<p>
				We may update Our Privacy Policy from time to time without any prior
				notice.
			</p>

			<p>
				It is your responsibility to review periodically for these changes.
				Changes to this Privacy Policy are effective when they are published on
				this page.
			</p>

			<h2>Contact Us</h2>
			<p>
				If you have any questions about this Privacy Policy, You can contact us:
			</p>
			<ul>
				<li>
					By visiting this page on our website:
					<a
						href="https://arultharummahaperiyava.com/join"
						rel="external noreferrer nofollow noopener"
						target="_blank"
					>
						https://arultharummahaperiyava.com/join
					</a>
				</li>

				<li>
					By Email:
					<a
						href="mailto:arultharummahaperiyava@gmail.com"
						rel="external noreferrer nofollow noopener"
						target="_blank"
					>
						arultharummahaperiyava@gmail.com
					</a>
				</li>
			</ul>
		</div>
	);
}

export default Privacy;
