import React from "react";
import { useSelector } from "react-redux";
import * as DOMPurify from "dompurify";

/* Importing Styles */
import "../styles/about.css";

export default function About() {
	const about = useSelector((state) => state.reducer.data.website.about);

	return (
		<section className="about" id="about">
			<div className="about-blob">
				<img
					loading="eager"
					width="10"
					height="10"
					title="ATM Global Group"
					src="./assets/images/about_blob.svg"
					alt=""
				/>
			</div>
			<div className="about-hero">
				<div className="about-left">
					<img
						width="500"
						loading="eager"
						height="250"
						title="ATM Global Group"
						src="./assets/images/atm_final_1.webp"
						alt=""
					/>
				</div>
				<div className="about-right">
					<div className="about-hero-head">
						<h1>ABOUT ATM GLOBAL GROUP</h1>
						<img
							width="200"
							loading="lazy"
							height="150"
							title="ATM Global Group"
							src="./assets/icons/quote_icon.svg"
							alt=""
						/>
					</div>
					<div className="about-hero-text">
						<span
							dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about) }}
							style={{
								textJustify: "inter-word",
								whiteSpace: "pre-line",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								color: "#e3651d",
							}}
						></span>
					</div>
					<footer className="join-footer">
						<h2 className="footer-text">
							Periyava Charanam, Periyava Charanam, Periyava Charanam
						</h2>
						<p style={{ fontSize: 18, textDecoration: "underline" }}>
							{" "}
							Quick Links
						</p>
						<div className="join-footer-content">
							<a href="/home">Home</a>
							<a href="/about-atm">About ATM</a>
							<a href="/activities">Our Activities</a>
							<a href="/gallery">Gallery</a>
							<a href="/announcements">Announcements</a>
							<a href="/terms">Terms and Conditions</a>
							<a href="/privacy">Privacy Policy</a>
						</div>
					</footer>
				</div>
			</div>
		</section>
	);
}
