import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Icon,
	IconButton,
	Tooltip,
} from "@mui/material";
import MarkdownIt from "markdown-it/lib";
import React, { useState, useEffect } from "react";
import {
	FilledButton,
	TextButton,
	TextInput,
} from "../../shared/utils/components";
import MdEditor from "react-markdown-editor-lite";

import Neon from "../../shared/data/neon";
import SyncIcon from "@mui/icons-material/Sync";

// import style manually
import "react-markdown-editor-lite/lib/index.css";
import { Close, Delete, DoneAll } from "@mui/icons-material";
import { v4 } from "uuid";
import insert from "markdown-it-ins";
import dateFormat from "dateformat";
import { useSelector } from "react-redux";
import ExportExcel from "../../shared/utils/excel";

function Activities({ open, onClose, data, id }) {
	const [_data, setData] = useState(
		data || {
			description: "",
			status: true,
			title: "",
			participants: [],
		}
	);
	const [processing, setProcessing] = useState(false);

	const [openDel, setOpenDel] = useState(false);

	useEffect(() => {
		setData(
			data || { description: "", status: true, title: "", participants: [] }
		);
	}, [data]);

	function submit() {
		setProcessing(true);

		if (!id) {
			Neon.put(`/activities/${v4()}`, _data).then(() => {
				window.location.reload();
			});
		} else {
			Neon.put(`/activities/${id}`, _data).then(() => {
				window.location.reload();
			});
		}
	}

	function _delete() {
		setProcessing(true);

		Neon.delete(`/activities/${id}`).then(() => {
			onClose();
			window.location.reload();
		});
	}

	const mdParser = new MarkdownIt({
		html: true,
		linkify: true,
		typographer: true,
		highlight(str, lang) {},
	}).use(insert);

	return (
		<Dialog maxWidth="xl" fullWidth open={open} onClose={onClose}>
			<DialogTitle
				sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
			>
				{_data.title || "New Activity"}

				{id && (
					<Tooltip title="Delete Activity">
						<IconButton onClick={() => setOpenDel(true)}>
							<Icon>
								<Delete htmlColor="tomato" />
							</Icon>
						</IconButton>
					</Tooltip>
				)}
			</DialogTitle>
			<DialogContent>
				<TextInput
					label="Title"
					required
					value={_data.title}
					onChange={(e) => setData({ ..._data, title: e.target.value })}
					disabled={processing}
					sx={{
						marginTop: 1,
						marginBottom: 1,
						width: "100%",
					}}
				/>
				Description *
				<MdEditor
					style={{ height: "100%" }}
					placeholder="Enter Description for the Activity >> Preview it in right side"
					renderHTML={(text) => mdParser.render(text)}
					disabled={processing}
					value={_data.description}
					onChange={({ html, text }) =>
						setData({ ..._data, description: text })
					}
				/>
			</DialogContent>
			<DialogActions>
				<FilledButton disabled={processing || !_data.title} onClick={submit}>
					Submit
				</FilledButton>
				<TextButton
					disableFocusRipple
					disableTouchRipple
					disableRipple
					onClick={onClose}
				>
					Cancel
				</TextButton>
			</DialogActions>

			<Dialog fullWidth open={openDel} onClose={() => setOpenDel(false)}>
				<DialogTitle>Delete Activity</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete the activity{" "}
						<span style={{ fontWeight: "bold" }}>{_data.title}</span>{" "}
						permanently?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<TextButton
						disableFocusRipple
						disableTouchRipple
						disableRipple
						onClick={_delete}
					>
						Delete
					</TextButton>
					<TextButton
						disableFocusRipple
						disableTouchRipple
						disableRipple
						onClick={() => setOpenDel(false)}
					>
						Cancel
					</TextButton>
				</DialogActions>
			</Dialog>
		</Dialog>
	);
}

function Announcements({ open, onClose, data, id }) {
	const [_data, setData] = useState(data || {});
	const [processing, setProcessing] = useState(false);

	const [openDel, setOpenDel] = useState(false);

	useEffect(() => {
		setData(data || {});
	}, [data]);

	function submit() {
		setProcessing(true);

		if (!id) {
			Neon.put(`/announcements/${v4()}`, _data).then(() => {
				onClose();
				window.location.reaload();
			});
		} else {
			Neon.put(`/announcements/${id}`, _data).then(() => {
				onClose();
				window.location.reaload();
			});
		}
	}

	function _delete() {
		setProcessing(true);

		Neon.delete(`/announcements/${id}`).then(() => {
			onClose();
			window.location.reload();
		});
	}

	const mdParser = new MarkdownIt({
		html: true,
		linkify: true,
		typographer: true,
		highlight(str, lang) {},
	}).use(insert);

	return (
		<Dialog maxWidth="xl" fullWidth open={open} onClose={onClose}>
			<DialogTitle
				sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
			>
				{_data.title || "New Announcement"}

				{id && (
					<Tooltip title="Delete Announcement">
						<IconButton onClick={() => setOpenDel(true)}>
							<Icon>
								<Delete htmlColor="tomato" />
							</Icon>
						</IconButton>
					</Tooltip>
				)}
			</DialogTitle>
			<DialogContent>
				<TextInput
					label="Title"
					required
					value={_data.title}
					onChange={(e) => setData({ ..._data, title: e.target.value })}
					disabled={processing}
					sx={{
						marginTop: 1,
						marginBottom: 1,
						width: "100%",
					}}
				/>
				Description *
				<MdEditor
					style={{ height: "100%" }}
					placeholder="Enter Description for the Announcement >> Preview it in right side"
					renderHTML={(text) => mdParser.render(text)}
					value={_data.description}
					disabled={processing}
					onChange={({ html, text }) =>
						setData({ ..._data, description: text })
					}
				/>
			</DialogContent>
			<DialogActions>
				<FilledButton
					disabled={processing || !_data.title || !_data.description}
					onClick={submit}
				>
					Submit
				</FilledButton>
				<TextButton
					disableFocusRipple
					disableTouchRipple
					disableRipple
					onClick={onClose}
				>
					Cancel
				</TextButton>
			</DialogActions>

			<Dialog open={openDel} onClose={() => setOpenDel(false)}>
				<DialogTitle>Delete Announcement</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete the announcement{" "}
						<span style={{ fontWeight: "bold" }}>{_data.title}</span>{" "}
						permanently?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<TextButton
						disableFocusRipple
						disableTouchRipple
						disableRipple
						onClick={_delete}
					>
						Delete
					</TextButton>
					<TextButton
						disableFocusRipple
						disableTouchRipple
						disableRipple
						onClick={() => setOpenDel(false)}
					>
						Cancel
					</TextButton>
				</DialogActions>
			</Dialog>
		</Dialog>
	);
}

function FixedActs({ open, onClose, data, vps = false }) {
	const members = useSelector((state) => state.reducer.data.members);
	const [processing, setProcessing] = useState(false);

	const [excel, setExcel] = useState([]);

	async function update() {
		setProcessing(true);

		let url = "";
		if (vps) url = "/vps";
		else url = "/svs";

		for (var d of done) {
			await Neon.delete(`${url}/${d}`);
		}

		window.location.reload();
	}

	const [done, setDone] = useState([]);

	const [array, setArray] = useState([]);
	useEffect(() => {
		if (!data) return;
		const buffer = [];
		let buffer_excel = [];

		for (let id in data) {
			buffer.push({ id, ...data[id] });
			buffer_excel.push({ ...members[data[id].u_id], ...data[id] });
		}

		setArray(buffer);
		setExcel(buffer_excel);

		// eslint-disable-next-line
	}, [data]);

	const vpsExport = () =>
		ExportExcel(
			excel,
			[
				"sno.",
				"Receipt Name",
				"Mobile No.",
				"Address",
				"Amount Paid (₹)",
				"Mode of Payment",
				"Transaction No.",
				"Date of Payment",
				"Reason",
				"Sankalpam Date",
				"Sankalpam Name",
				"Gothram",
				"Star",
				"Rasi",
				"Specific Prarthanai",
			],
			[
				"receipt_name",
				"mob_no",
				"address",
				"amt",
				"mode",
				"trn_no",
				"payment_date",
				"reason",
				"request_date",
				"sankalpam_name",
				"gothram",
				"star",
				"rasi",
				"prathanai",
			],
			`ATM VPS List`,
			vps
		);
	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<DialogTitle>
				{vps ? "Veda Patashala Kaingaryam" : "Deivathin Kural Vasippu"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText sx={{ textAlign: "center" }}>
					{vps && "Please remove old entries"}
				</DialogContentText>
				{done.length > 0 && (
					<FilledButton
						disabled={processing}
						onClick={update}
						startIcon={<SyncIcon />}
					>
						Update Data
					</FilledButton>
				)}
				<div className="vps-container">
					{array.length > 0
						? array.map((item, i) => {
								return (
									vps && (
										<div
											className={`vps-card ${
												done.includes(item.id) && "vps-card-disabled"
											}`}
											key={i}
										>
											<div className="vps-card-left">
												<h3>By {members[item.u_id].fullname}</h3>
												<h4>{members[item.u_id].mob_no}</h4>
												<p
													style={{
														whiteSpace: "pre-wrap",
														textAlign: "justify",
														textJustify: "inter-word",
													}}
												>
													{`Requested Date: ${dateFormat(
														item.request_date,
														"dddd, dd/mm/yyyy"
													)}`}
												</p>
												<p id="vps-created-on">
													Created on:{" "}
													{item.timestamp
														? dateFormat(
																item.timestamp,
																"dd/mm/yyyy @ HH:MM:ss"
														  )
														: "Data not Available"}
												</p>
											</div>
											<div className="vps-card-right">
												<Tooltip
													title={
														done.includes(item.id) ? "Deselect" : "Mark Done"
													}
												>
													{done.includes(item.id) ? (
														<IconButton
															onClick={() => {
																setDone(done.filter((i) => i !== item.id));
															}}
														>
															<Icon>
																<Close htmlColor="orange" />
															</Icon>
														</IconButton>
													) : (
														<IconButton
															onClick={() => {
																setDone([...done, item.id]);
															}}
														>
															<Icon>
																<DoneAll htmlColor="orange" />
															</Icon>
														</IconButton>
													)}
												</Tooltip>
											</div>
										</div>
									)
								);
						  })
						: "No Data available"}
				</div>
			</DialogContent>
			<DialogActions>
				<FilledButton
					onClick={() => {
						vps
							? vpsExport()
							: ExportExcel(
									excel,
									[
										"sno.",
										"Full Name",
										"Country Code",
										"Mobile No.",
										"Date Read",
										"Group Number",
									],
									[
										"fullname",
										"country_code",
										"mob_no",
										"request_date",
										"group_no",
									],
									`ATM DK List`,
									vps
							  );
					}}
					disabled={processing || !excel.length > 0}
				>
					Download Excel
				</FilledButton>
				<TextButton disabled={processing} onClick={onClose}>
					Close
				</TextButton>
			</DialogActions>
		</Dialog>
	);
}

export { Activities, Announcements, FixedActs };
