import { ChevronRight, Close, Download, MoreVert } from "@mui/icons-material";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Icon,
	IconButton,
	Switch,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FilledButton, TextButton } from "../../shared/utils/components";
import ExportExcel from "../../shared/utils/excel";
import { Activities, Announcements } from "./Modals";
import MdEditor from "react-markdown-editor-lite";
import insert from "markdown-it-ins";
import MarkdownIt from "markdown-it/lib";

// MdEditor styles
import "react-markdown-editor-lite/lib/index.css";
import Neon from "../../shared/data/neon";

export default function Card({
	data = {},
	isOk = true,
	activities = false,
	user = {},
}) {
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [participants, setParticipants] = useState([]);
	const [processing, setProcessing] = useState(false);

	const [status, setStatus] = useState(false);

	const members = useSelector((state) => state.reducer.data.members);

	const mdParser = new MarkdownIt({
		html: true,
		linkify: true,
		typographer: true,
		highlight(str, lang) {},
	}).use(insert);

	useEffect(() => {
		if (!isOk) return;

		if (!activities) return;
		setStatus(data.status);

		if (data.participants.length === 0) return;

		let _pData = [];
		data.participants.forEach((p) => {
			_pData.push({ ...members[p] });
		});

		setParticipants(_pData);

		// eslint-disable-next-line
	}, []);

	return (
		<div className="card">
			<div
				className="card-text"
				style={{ cursor: "pointer" }}
				onClick={() => setOpen(true)}
			>
				<h3>
					{activities && (data.status ? "🟢" : "⭕")}
					{data.title}
				</h3>
				<p>
					{data.description.length > 35
						? `${data.description.replace(/[#+*]+/g, "").substring(0, 35)}...`
						: data.description.replace(/[#+*]+/g, "")}
				</p>
			</div>
			<div className="card-trailing">
				<IconButton onClick={() => setOpen(true)}>
					<Icon>
						<ChevronRight />
					</Icon>
				</IconButton>
			</div>

			<Dialog
				maxwidth="lg"
				fullScreen
				open={open}
				onClose={() => setOpen(false)}
			>
				<DialogTitle>{data.title}</DialogTitle>
				<DialogContent>
					<MdEditor
						style={{ height: "fit-content" }}
						renderHTML={(text) => mdParser.render(text)}
						value={data.description}
						readOnly
						view={{
							menu: false,
							md: false,
						}}
					/>
					<DialogContentText>
						<br />
						<br />
						{isOk && activities && (
							<strong>
								Number of Registered Participants : {participants.length}
							</strong>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{ dispaly: "flex", flexWrap: "wrap", width: "100%" }}
				>
					{isOk && (
						<>
							{((activities && (user.acts || user.isSuper)) ||
								(!activities && (user.anns || user.isSuper))) && (
								<TextButton
									disableFocusRipple
									disabled={processing}
									disableTouchRipple
									disableRipple
									startIcon={<MoreVert />}
									onClick={() => setOpenEdit(true)}
								>
									More Actions
								</TextButton>
							)}

							{activities && (
								<>
									<FormControlLabel
										control={
											<Switch
												checked={status}
												disabled={processing}
												onChange={(e) => {
													setProcessing(true);
													setStatus(e.target.checked);
													Neon.put(`/activities/${data.id}`, {
														...data,
														status: e.target.checked,
													}).then(() => {
														window.location.reload();
													});
												}}
											/>
										}
										label={
											status
												? "Open For Participation"
												: "Closed For Participation"
										}
										labelPlacement="start"
									/>
									<TextButton
										disableFocusRipple
										disableTouchRipple
										disableRipple
										disabled={participants.length === 0 || processing}
										startIcon={<Download />}
										onClick={() => {
											ExportExcel(
												participants,
												[
													"sno.",
													"Fullname",
													"country code",
													"mobile number",
													"residential address",
													"country",
													"pin code",
													"main group no.",
												],
												[
													"fullname",
													"country_code",
													"mob_no",
													"address",
													"country",
													"pin_code",
													"group_no",
												],
												`Participants List - ${data.title}`
											);
											setOpen(false);
										}}
									>
										Download Participants Data
									</TextButton>
								</>
							)}
						</>
					)}
					<FilledButton
						onClick={() => setOpen(false)}
						disabled={processing}
						startIcon={<Close htmlColor="white" />}
					>
						Close
					</FilledButton>
				</DialogActions>
			</Dialog>

			<Activities
				data={data}
				open={activities && openEdit}
				onClose={() => setOpenEdit(false)}
				id={data.id}
			/>

			<Announcements
				data={data}
				open={!activities && openEdit}
				onClose={() => setOpenEdit(false)}
				id={data.id}
			/>
		</div>
	);
}
