import {
	Add,
	AddAPhoto,
	ChevronLeft,
	Close,
	Delete,
} from "@mui/icons-material";
import {
	Alert,
	AlertTitle,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Slide,
	Snackbar,
	Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	FilledButton,
	TextButton,
	TextInput,
} from "../shared/utils/components";
import SaveIcon from "@mui/icons-material/Save";
import { useSelector } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../shared/data/firebase";
import { v4 } from "uuid";
import Image from "./misc/Image";
import Neon from "../shared/data/neon";
import RichTextEditor from "react-rte";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ReactPlayer from "react-player";

export default function EditWeb() {
	const navigate = useNavigate();
	const about = useSelector((state) => state.reducer.data.website.about);
	const activities = useSelector(
		(state) => state.reducer.data.website.activities
	);
	const gallery = useSelector((state) => state.reducer.data.website.gallery);
	const videos = useSelector((state) => state.reducer.data.website.youtube);
	const loading = useSelector((state) => state.reducer.meta.loading);

	const [imgLoading, setImgLoading] = useState(false);
	const [_imgs, set_imgs] = useState([]);

	const [_activities, set_activities] = useState(
		RichTextEditor.createEmptyValue()
	);
	const [_about, set_about] = useState(RichTextEditor.createEmptyValue());

	const [fileData, setFileData] = useState({
		title: "",
		file: "",
	});

	const [vidData, setVidData] = useState({
		url: "",
		error: "",
	});
	const [dialog_vid, setDialog_vid] = useState(false);
	const [_vids, set_vids] = useState([]);

	const toolbarConfig = {
		// Optionally specify the groups to display (displayed in the order listed).
		display: [
			"INLINE_STYLE_BUTTONS",
			"BLOCK_TYPE_BUTTONS",
			"LINK_BUTTONS",
			"BLOCK_TYPE_DROPDOWN",
			"HISTORY_BUTTONS",
		],
		INLINE_STYLE_BUTTONS: [
			{ label: "Bold", style: "BOLD" },
			{ label: "Italic", style: "ITALIC" },
			{ label: "Underline", style: "UNDERLINE" },
		],
		BLOCK_TYPE_DROPDOWN: [
			{ label: "Normal", style: "unstyled" },
			{ label: "Heading Large", style: "header-one" },
			{ label: "Heading Medium", style: "header-two" },
			{ label: "Heading Small", style: "header-three" },
		],
		BLOCK_TYPE_BUTTONS: [
			{ label: "UL", style: "unordered-list-item" },
			{ label: "OL", style: "ordered-list-item" },
		],
	};

	const [open, setOpen] = useState(false);
	const [dialog, setDialog] = useState(false);

	const [msg, setMsg] = useState("");

	useEffect(() => {
		if (loading) return;

		set_about(RichTextEditor.createValueFromString(about, "html"));
		set_activities(RichTextEditor.createValueFromString(activities, "html"));

		var _buffer_imgs = [];

		for (let img in gallery) {
			_buffer_imgs.push({ ...gallery[img] });
		}
		set_imgs(_buffer_imgs);

		var _buffer_vids = [];

		for (let vid in videos) {
			_buffer_vids.push({ id: vid, ...videos[vid] });
		}
		set_vids(_buffer_vids);

		// eslint-disable-next-line
	}, [loading]);

	function save() {
		Neon.put("/website", {
			about: _about.toString("html"),
			activities: _activities.toString("html"),
			gallery: gallery,
			youtube: videos,
		}).then((_) => {
			setMsg("Website updated successfully");
			setOpen(true);
		});
	}

	function VidUpload() {
		setImgLoading(true);

		Neon.put(`/website/youtube/${v4()}`, {
			url: vidData.url,
			timestamp: Date.now(),
		})
			.then((_) => {
				setDialog(false);
				setMsg("Video uploaded successfully");
				setOpen(true);
				setVidData({ url: "", error: "" });
			})
			.catch((e) => console.error(e));
	}

	function DelVid(i) {
		const id = _vids[i].id;
		Neon.delete(`/website/youtube/${id}`).then(() => {
			setMsg("Video link deleted successfully");
			setOpen(true);
		});
	}

	function imageUpload() {
		if (fileData.file.size > 10485760) {
			return;
		}
		const fileName = `${v4()}_${fileData.file.name.replace(" ", "_")}`;

		var uploadRef = ref(storage, `gallery/${fileName}`);

		setImgLoading(true);

		uploadBytes(uploadRef, fileData.file).then((res) => {
			Neon.put(`/website/gallery/${fileName}`, {
				title: fileData.title,
				url: fileName,
				timestamp: Date.now(),
			})
				.then((_) => {
					setDialog(false);
					setMsg("Image uploaded successfully");
					setOpen(true);
					setFileData({});
				})
				.catch((e) => console.error(e));
		});
	}

	return (
		<div className="contanier paddings">
			<Snackbar
				open={open}
				autoHideDuration={2000}
				TransitionComponent={Slide}
				anchorOrigin={{ horizontal: "right", vertical: "top" }}
				onClose={() => {
					setOpen(false);
					window.location.reload();
				}}
			>
				<Alert severity="success" onClose={() => setOpen(false)}>
					<AlertTitle>SUCCESS</AlertTitle>
					{msg}
				</Alert>
			</Snackbar>
			<div className="edit-web-head">
				<IconButton onClick={() => navigate("/admin")}>
					<Icon fontSize="large">
						<ChevronLeft fontSize="large" htmlColor="#F2C31A" />
					</Icon>
				</IconButton>
				<h1>Edit Website</h1>
				<FilledButton
					disabled={
						activities === _activities.toString("html") &&
						about === _about.toString("html")
					}
					startIcon={<SaveIcon />}
					onClick={save}
				>
					Save Changes
				</FilledButton>
			</div>
			{loading ? (
				<div className="loading">
					<div className="loading">
						<CircularProgress thickness={4} sx={{ color: "gold" }} />
					</div>
				</div>
			) : (
				<div className="edit-web-hero">
					<h2>About ATM</h2>
					<RichTextEditor
						value={_about}
						onChange={(val) => set_about(val)}
						toolbarConfig={toolbarConfig}
						placeholder='Content for "About ATM" section in the website'
					/>
					<h2>Our Activities</h2>
					<RichTextEditor
						value={_activities}
						onChange={(val) => set_activities(val)}
						placeholder='Content for "Our Activities" section in the website'
					/>
					{/* <TextInput
						value={_about}
						onChange={(e) => set_about(e.target.value)}
						multiline
						minRows={20}
						label="About us"
					/>
					<TextInput
						value={_activities}
						onChange={(e) => set_activities(e.target.value)}
						multiline
						minRows={20}
						label="Our Activities"
					/> */}
					<div className="edit-gallery">
						<div className="edit-gallery-contents">
							<div className="gallery-edit-title">
								<h2>Image Gallery</h2>
								<Tooltip placement="top" title="Add an image">
									<IconButton onClick={() => setDialog(true)}>
										<Icon>
											<Add htmlColor="orange" />
										</Icon>
									</IconButton>
								</Tooltip>
							</div>

							<div className="gallery-images">
								{_imgs.length !== 0
									? _imgs.map((item, i) => {
											return (
												<Image data={item} key={i} src={item.url} alt="" />
											);
									  })
									: "No Images"}
							</div>
						</div>
					</div>

					<div className="edit-gallery">
						<div className="edit-gallery-contents">
							<div className="gallery-edit-title">
								<h2>Video Gallery</h2>
								<Tooltip placement="top" title="Add a Youtube Video">
									<IconButton onClick={() => setDialog_vid(true)}>
										<Icon>
											<Add htmlColor="orange" />
										</Icon>
									</IconButton>
								</Tooltip>
							</div>
							<div className="gallery-images">
								{_vids.length !== 0
									? _vids.map((item, i) => {
											return (
												<div>
													<ReactPlayer
														url={item.url}
														key={i}
														width={300}
														height={200}
													/>
													<Tooltip title="Delete Link">
														<IconButton onClick={() => DelVid(i)}>
															<Icon>
																<Delete htmlColor="tomato" />
															</Icon>
														</IconButton>
													</Tooltip>
												</div>
											);
									  })
									: "No Video Links"}
							</div>
						</div>
					</div>
				</div>
			)}

			{/* ----------------- * DAILOG BOXES * ----------  */}

			<Dialog open={dialog} onClose={() => setDialog(false)}>
				<DialogTitle>
					Add Image
					<Icon>
						<AddAPhoto sx={{ color: "orange" }} />
					</Icon>
				</DialogTitle>
				<DialogContent>
					{imgLoading ? (
						<div className="loading">
							<div className="loading">
								<CircularProgress thickness={4} sx={{ color: "gold" }} />
							</div>
						</div>
					) : (
						<div className="gallery-edit">
							<label>
								<input
									disabled={imgLoading}
									onChange={(e) =>
										setFileData({ ...fileData, file: e.target.files[0] })
									}
									type="file"
									accept="image/png, image/jpg, image/jpeg, image/webp, image/gif"
								/>
							</label>
							<TextInput
								onChange={(e) =>
									setFileData({ ...fileData, title: e.target.value })
								}
								label="Title"
								disabled={imgLoading}
								placeholder="Enter a caption for the image"
							/>
						</div>
					)}
					<DialogActions>
						<TextButton
							onClick={imageUpload}
							disabled={!fileData.file || imgLoading}
							startIcon={<CloudUploadIcon />}
						>
							upload
						</TextButton>
						<FilledButton
							startIcon={<Close />}
							disabled={loading || imgLoading}
							onClick={() => setDialog(false)}
						>
							Cancel
						</FilledButton>
					</DialogActions>
				</DialogContent>
			</Dialog>

			<Dialog
				open={dialog_vid}
				fullWidth
				maxWidth="md"
				onClose={() => {
					setDialog_vid(false);
					setVidData({ url: "", error: "" });
				}}
			>
				<DialogTitle>
					Add Youtube Video
					<Icon>
						<YouTubeIcon sx={{ color: "orange" }} />
					</Icon>
				</DialogTitle>
				<DialogContent>
					{imgLoading ? (
						<div className="loading">
							<div className="loading">
								<CircularProgress thickness={4} sx={{ color: "gold" }} />
							</div>
						</div>
					) : (
						<div className="gallery-edit">
							{vidData.url ? (
								<ReactPlayer
									url={vidData.url || ""}
									onError={(e) => {
										setVidData({
											url: "",
											error: "The video can't be loaded.. Invalid Url",
										});
									}}
								/>
							) : (
								(vidData.error && (
									<span style={{ color: "tomato" }}>{vidData.error}</span>
								)) ||
								"Preview will appear here ..."
							)}

							<TextInput
								onChange={(e) => setVidData({ url: e.target.value })}
								label="YouTube URL"
								autoFocus
								disabled={imgLoading}
								placeholder="https://www.youtube.com/watch?v="
							/>
						</div>
					)}
					<DialogActions>
						<TextButton
							onClick={VidUpload}
							disabled={!vidData.url || imgLoading}
							startIcon={<CloudUploadIcon />}
						>
							upload
						</TextButton>
						<FilledButton
							startIcon={<Close />}
							disabled={loading || imgLoading}
							onClick={() => {
								setDialog_vid(false);
								setVidData({ url: "", error: "" });
							}}
						>
							Cancel
						</FilledButton>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</div>
	);
}
