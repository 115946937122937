import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: {
		activities: {},
		announcements: {},
		members: {},
		website: {
			about: "",
			activities: "",
		},
		payments_received: {},
	},
	meta: {
		loading: false,
	},
};

const RootReducer = createSlice({
	name: "root",
	initialState: initialState,
	reducers: {
		startLoading: (state) => {
			state.meta.loading = true;
		},
		stopLoading: (state) => {
			state.meta.loading = false;
		},

		fetchData: (state, action) => {
			state.data.website.about = action.payload.about;
			state.data.website.gallery = action.payload.gallery;
			state.data.website.youtube = action.payload.youtube;
			state.data.website.activities = action.payload.activities;
		},

		fetch_announcements: (state, action) => {
			state.data.announcements = action.payload;
		},
		fetch_activities: (state, action) => {
			state.data.activities = action.payload;
		},

		fetch_members: (state, action) => {
			state.data.members = action.payload;
		},
		fetch_vps: (state, action) => {
			state.data.vps = action.payload;
		},
		fetch_svs: (state, action) => {
			state.data.svs = action.payload;
		},
		fetch_payments: (state, action) => {
			state.data.payments_received = action.payload;
		},
	},
});

export default RootReducer;
export const {
	startLoading,
	stopLoading,
	fetchData,
	fetch_announcements,
	fetch_members,
	fetch_svs,
	fetch_vps,
	fetch_activities,
	fetch_payments,
} = RootReducer.actions;
