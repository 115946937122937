import React from "react";
import { useParams } from "react-router-dom";

function Fail() {
	const { id } = useParams();

	return (
		<div className="online-donation success">
			<div className="home-banner">
				<img
					src="/assets/images/atm_banner.png"
					title="ATM Global Group"
					width="800"
					loading="eager"
					height="180"
					alt=""
				/>
			</div>

			<div className="success-box">
				<div className="success-img">
					<img src="/assets/images/fail.gif" alt="" />
				</div>
				<h2>Payment Failed</h2>
				<p style={{ fontSize: 17 }}>
					Your transaction has failed due to network issues.
				</p>
				<p>
					If the amount has been debited from your account it will be refunded
					back within 5-7 working days.
				</p>

				<p>Transaction Ref. No: {id}</p>

				<br />
				<div className="success-btns">
					<a href="/">Go to Home Page</a>{" "}
				</div>
			</div>
		</div>
	);
}

export default Fail;
