import { ChevronRight, Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Alert,
	AlertTitle,
	CircularProgress,
	FormControlLabel,
	Icon,
	IconButton,
	InputAdornment,
	Slide,
	Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FilledButton, TextInput } from "../shared/utils/components";
import Checkbox from "@mui/material/Checkbox";

/* Importing Styles */
import "../styles/admin.css";
import Neon from "../shared/data/neon";
import {
	fetch_activities,
	fetch_members,
	// fetch_svs,
	fetch_vps,
	startLoading,
	stopLoading,
} from "../shared/data/redux/reducers";

export default function AdminLogin() {
	const [mobile, setMobile] = useState("");
	const [password, setPassword] = useState("");
	const [visible, setVisible] = useState(false);
	const [remember, setRemember] = useState(false);
	const [open, setOpen] = useState(false);

	const members = useSelector((state) => state.reducer.data.members);
	const loading = useSelector((state) => state.reducer.meta.loading);

	const navigate = useNavigate();

	const getmem = Neon.fetch("/members").then((res) => {
		window.store.dispatch(fetch_members(res));
	});

	/* const getsvs = Neon.fetch("/svs").then((res) => {
		window.store.dispatch(fetch_svs(res));
	}); */

	const getvps = Neon.fetch("/vps").then((res) => {
		window.store.dispatch(fetch_vps(res));
	});

	const getacts = Neon.fetch("/activities").then((res) => {
		window.store.dispatch(fetch_activities(res));
	});

	useEffect(() => {
		const _id = window.localStorage.getItem("user");

		window.store.dispatch(startLoading());
		const promises = [getmem, getvps, getacts];
		Promise.all(promises).then(() => window.store.dispatch(stopLoading()));

		if (_id !== null) {
			window.sessionStorage.setItem("user", _id);
			navigate("/admin");
			return;
		}
		// eslint-disable-next-line
	}, []);

	function login() {
		for (let id in members) {
			if (
				members[id].mob_no === mobile &&
				members[id].password === password &&
				members[id].is_admin
			) {
				window.sessionStorage.setItem("user", id);
				if (remember) {
					window.localStorage.setItem("user", id);
				}
				navigate("/admin");
			}

			setOpen(true);
		}
	}

	return (
		<div className="container flex-vert items-center greyback">
			<Snackbar
				open={open}
				autoHideDuration={4000}
				TransitionComponent={Slide}
				anchorOrigin={{ horizontal: "right", vertical: "top" }}
				onClose={() => setOpen(false)}
			>
				<Alert severity="error" onClose={() => setOpen(false)}>
					<AlertTitle>Error</AlertTitle>
					Email Address or Passcode is incorrect
				</Alert>
			</Snackbar>
			<div className="login-header">
				<img src="/assets/images/atm_banner.png" alt="" />
				<div className="login-header-text">
					<h1>Welcome</h1>
					<p>Please login to your administrator account to continue</p>
				</div>
			</div>
			<div className="login-form">
				{loading ? (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center"
						}}
					>
						<CircularProgress thickness={4} />
						<p>Loading Members data may take some time</p>
					</div>
				) : (
					<form
						className="login-form-content"
						action="/"
						onSubmit={(e) => e.preventDefault()}
					>
						<TextInput
							placeholder="Enter Mobile No."
							type="text"
							label="Mobile Number"
							color="success"
							value={mobile}
							onChange={(e) => setMobile(e.target.value)}
							helperText="Enter without country code"
						/>

						<TextInput
							placeholder="●●●●●●●●●●●"
							type={!visible ? "password" : "text"}
							label="Passcode"
							color="success"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setVisible(!visible)}>
											<Icon>
												{visible ? <Visibility /> : <VisibilityOff />}
											</Icon>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<FormControlLabel
							control={
								<Checkbox
									checked={remember}
									onChange={(e) => setRemember(e.target.checked)}
								/>
							}
							label="Keep me signed in"
						/>

						<FilledButton
							disabled={!mobile || !password}
							startIcon={<ChevronRight color="white" />}
							onClick={login}
						>
							Login
						</FilledButton>
					</form>
				)}
			</div>
		</div>
	);
}
