import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../shared/utils/Navbar";
import About from "../About";

export default function AboutRoute() {
	return (
		<>
			<Helmet>
				<title>About ATM</title>
				<meta
					name="description"
					content="With the Anugraha Bhashanam blessings of HH Pujyasri Sri Bala Periyava of Sri Kanchi Kamakoti Peetam, “Arultharum Mahaperiyava Global Group” was formed on 29th May 2021. "
				/>
				<link rel="canonical" href="/about-atm" />
			</Helmet>
			<Navbar active="about" />
			<About />
		</>
	);
}
