const { default: axios } = require("axios");
const { v4 } = require("uuid");

const REQ_URL = process.env.REACT_APP_REQ_URL;
const BASE_URL = `${REQ_URL}/${v4()}/${v4()}/atm_db`;

const api = axios.create({
	baseURL: BASE_URL,
	timeout: 60000,
});

const pyt_api = axios.create({
	baseURL: process.env.REACT_APP_PYT_URL,
});

const Neon = {
	fetch: function (url) {
		return api
			.get(url || "")
			.then((res) => res.data)
			.catch((err) => err);
	},

	put: function (path, data) {
		return api
			.put(path, data)
			.then((res) => res.data)
			.catch((err) => err);
	},

	delete: function (path) {
		return api
			.delete(path)
			.then((res) => res.data)
			.catch((err) => err);
	},
	login: function (path, data) {
		return api
			.post(path, data)
			.then((res) => res.data)
			.catch((err) => err);
	},

	// Payment apis --------------------
	create_order: function (payment) {
		return pyt_api
			.post("/payments/order", payment)
			.then((res) => res.data)
			.catch((err) => err);
	},
	payment_success: function (data) {
		return pyt_api
			.post("/payments/success", data)
			.then((res) => res.data)
			.catch((err) => err);
	},

	verify_payment: function (razorpay_signature, order_id, payment_id) {
		return pyt_api
			.post("/payments/verify", {
				razorpay_signature: razorpay_signature,
				order_id: order_id,
				payment_id: payment_id,
			})
			.then((res) => res.data)
			.catch((err) => err);
	},

	get_payment: function (id) {
		return pyt_api
			.get(`/payments/get/${id}`)
			.then((res) => res)
			.catch((err) => err);
	},

	get_order: function (id) {
		return pyt_api
			.get(`/orders/get/${id}`)
			.then((res) => res)
			.catch((err) => err);
	},

	generate_receipt: function (receipt_no) {
		return pyt_api.get(`/public/receipt/${receipt_no}`);
	},

	mail: function (options) {
		return pyt_api.post("/mails", options);
	},
};

export default Neon;
