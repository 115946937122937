import {
	Add,
	DeleteForever,
	Edit,
	KeyboardBackspace,
} from "@mui/icons-material";
import {
	Alert,
	AlertTitle,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Icon,
	IconButton,
	Slide,
	Snackbar,
	Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	FilledButton,
	TextButton,
	TextInput,
} from "../shared/utils/components";
import DownloadIcon from "@mui/icons-material/Download";
import DvrIcon from "@mui/icons-material/Dvr";
import RepeatIcon from "@mui/icons-material/Repeat";
import Card from "./misc/Card";
import ExportExcel from "../shared/utils/excel";
import dateFormat from "dateformat";
import countapi from "countapi-js";
import LanguageIcon from "@mui/icons-material/Language";
import { Activities, Announcements, FixedActs } from "./misc/Modals";
import Neon from "../shared/data/neon";
import {
	fetch_activities,
	fetch_members,
	fetch_payments,
	// fetch_svs,
	fetch_vps,
	startLoading,
	stopLoading,
} from "../shared/data/redux/reducers";

export default function Admin() {
	const [user, setUser] = useState({});

	const [openActs, setOpenActs] = useState(false);
	const [openAnns, setOpenAnns] = useState(false);

	const [progress, setProgress] = useState(0);

	const [openVps, setOpenVps] = useState(false);
	const [openSvs, setOpenSvs] = useState(false);

	const [openDel, setOpenDel] = useState(false);
	const [delMob, setDelMob] = useState();
	const [delLoading, setDelLoading] = useState(false);
	const [delAlert, setDelAlert] = useState(false);

	const members = useSelector((state) => state.reducer.data.members);
	const loading = useSelector((state) => state.reducer.meta.loading);

	const payments_received = useSelector(
		(state) => state.reducer.data.payments_received
	);

	const activities = useSelector((state) => state.reducer.data.activities);
	const announcements = useSelector(
		(state) => state.reducer.data.announcements
	);

	const vps_data = useSelector((state) => state.reducer.data.vps);
	const svs_data = useSelector((state) => state.reducer.data.svs);

	const [views, setViews] = useState(null);

	useEffect(() => {
		setTimeout(() => {
			countapi
				.get("atm_website.com", "501a839a-e11c-4cec-827d-63ac0ec3deec")
				.then((r) => {
					setViews(r.value);
				});
		}, 3000);
	}, []);

	useEffect(() => {
		if (Object.keys(members).length === 0) {
			const getmem = Neon.fetch("/members").then((res) => {
				window.store.dispatch(fetch_members(res));
				setProgress(progress + 80);
			});

			/* const getsvs = Neon.fetch("/svs").then((res) => {
				window.store.dispatch(fetch_svs(res));
				setProgress(progress + 40);
			}); */

			const getvps = Neon.fetch("/vps").then((res) => {
				window.store.dispatch(fetch_vps(res));
				setProgress(progress + 10);
			});

			const getacts = Neon.fetch("/activities").then((res) => {
				window.store.dispatch(fetch_activities(res));
				setProgress(progress + 10);
			});

			const get_payments = Neon.fetch("/payments_received").then((res) => {
				window.store.dispatch(fetch_payments(res));
				setProgress(progress + 10);
			});
			window.store.dispatch(startLoading());
			const promises = [getmem, getvps, getacts, get_payments];
			Promise.all(promises).then(() => window.store.dispatch(stopLoading()));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members]);

	const navigate = useNavigate();
	const [data_activities, setData_activities] = useState([]);
	const [data_announcements, setData_announcements] = useState([]);
	const [ramaJapam, setRamaJapam] = useState([]);
	const [mpjapam, setMpjapam] = useState([]);

	const [payments, setPayments] = useState([]);

	const [exportMembers, setExportMembers] = useState([]);

	useEffect(() => {
		if (loading) return;
		var _id = window.sessionStorage.getItem("user");

		if (_id === null) {
			navigate("/admin/login");
			return;
		}

		setUser(members[_id]);

		let _data_acts = [];
		for (let id in activities) {
			_data_acts.push({ id, ...activities[id] });
		}
		setData_activities(_data_acts);

		let _data_anns = [];
		for (let id in announcements) {
			_data_anns.push({ id, ...announcements[id] });
		}
		setData_announcements(_data_anns);

		let rj = [];
		for (let id in members) {
			let name = members[id]["fullname"];
			let mob = members[id]["mob_no"];
			let total = members[id]["japam"]["total"];
			let last_on = members[id]["japam"]["last_japam"]["on"];
			let last_amt = members[id]["japam"]["last_japam"]["amt"];
			let group_no = members[id]["group_no"];

			rj.push({ name, mob, total, last_on, last_amt, group_no });
		}
		setRamaJapam(rj);

		let mpj = [];
		for (let id in members) {
			let name = members[id]["fullname"];
			let mob = members[id]["mob_no"];
			let group_no = members[id]["group_no"];
			let total = members[id]["mpjapam"]
				? members[id]["mpjapam"]["total"]
				: "-";
			let last_on = members[id]["mpjapam"]
				? members[id]["mpjapam"]["last_japam"]["on"]
				: "-";
			let last_amt = members[id]["mpjapam"]
				? members[id]["mpjapam"]["last_japam"]["amt"]
				: "-";

			mpj.push({ name, mob, total, last_on, last_amt, group_no });
		}
		setMpjapam(mpj);

		let buffer_members = [];
		for (let m in members) {
			buffer_members.push({ m, ...members[m] });
		}
		setExportMembers(buffer_members);

		let pyts = [];
		for (let pyt in payments_received) {
			pyts.push(payments_received[pyt]);
		}
		setPayments(pyts);

		// eslint-disable-next-line
	}, [loading]);

	function DelMember() {
		var del = "";
		for (let id in members) {
			if (members[id].mob_no === delMob) {
				del = id;
				break;
			}
		}

		if (del === "" || !del) {
			alert("Member not found !!");
			return;
		}

		setDelLoading(true);

		Neon.delete(`/members/${del}`).then(() => {
			setDelLoading(false);
			setOpenDel(false);
		});
	}

	return (
		<div className="container flex-vert greyback paddings">
			{loading ? (
				<div
					className="loading"
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<CircularProgress thickness={4} />
					<p>Loading Members data may take some time</p>
					<div
						className="statusbar"
						style={{
							width: 300,
						}}
					>
						<div
							className="statusfill"
							style={{
								width: (progress / 100) * 300,
								backgroundColor: "orange",
								height: 10,
								borderRadius: 4,
							}}
						></div>
					</div>
					<div className="progress-txt">{progress}%</div>
				</div>
			) : (
				<>
					<Snackbar
						open={delAlert}
						autoHideDuration={3000}
						TransitionComponent={Slide}
						anchorOrigin={{ horizontal: "right", vertical: "top" }}
						onClose={() => setDelAlert(false)}
					>
						<Alert
							severity="success"
							onClose={() => {
								setDelAlert(false);
								window.location.reload();
							}}
						>
							<AlertTitle>Member Deleted</AlertTitle>
							Member Deleted Successfully !!
						</Alert>
					</Snackbar>
					<div className="admin-banner">
						<img src="./assets/images/atm_banner.png" alt="" />
					</div>
					<div className="admin-header">
						<h1>Namaskaram {user && user.fullname}, 🙏</h1>
						<h3>
							No. of Visits :{" "}
							{views || <span style={{ fontSize: 15 }}>Counting 🔃 ... </span>}
						</h3>
					</div>
					<div className="admin-btns">
						<div className="admin-btns-head">
							<h2>Admin Controls</h2>
							<Icon>
								<DvrIcon htmlColor="orange" />
							</Icon>
						</div>
						<div className="admin-btns-contents">
							{user && (user.edit_website || user.isSuper) && (
								<TextButton
									onClick={() => navigate("/admin/website")}
									startIcon={<Edit color="white" />}
								>
									Edit Website
								</TextButton>
							)}
							{user && (user.rj || user.isSuper) && (
								<TextButton
									onClick={() =>
										ExportExcel(
											ramaJapam,
											[
												"sno.",
												"Fullname",
												"Mobile No.",
												`Total Count till ${dateFormat(
													new Date(),
													"dd/mm/yyyy"
												)}`,
												"Last Japam on",
												"Last count",
												"Main Group Number",
											],
											[
												"name",
												"mob",
												"total",
												"last_on",
												"last_amt",
												"group_no",
											],
											"ATM Rama Japam List"
										)
									}
									startIcon={<DownloadIcon color="white" />}
								>
									Rama Japam data
								</TextButton>
							)}
							{user && (user.rj || user.isSuper) && (
								<TextButton
									onClick={() =>
										ExportExcel(
											mpjapam,
											[
												"sno.",
												"Fullname",
												"Mobile No.",
												`Total Count till ${dateFormat(
													new Date(),
													"dd/mm/yyyy"
												)}`,
												"Last Japam on",
												"Last count",
												"Main Group Number",
											],
											[
												"name",
												"mob",
												"total",
												"last_on",
												"last_amt",
												"group_no",
											],
											"ATM Mahaperiyava Japam List"
										)
									}
									startIcon={<DownloadIcon color="white" />}
								>
									MahaPeriyava Nama Japam data
								</TextButton>
							)}
							{user && (user.vps || user.isSuper) && (
								<TextButton
									onClick={() => setOpenVps(true)}
									startIcon={<DownloadIcon color="white" />}
								>
									VPS Data
								</TextButton>
							)}
							{/* {user && (user.dk || user.isSuper) && (
								<TextButton
									onClick={() => setOpenSvs(true)}
									startIcon={<DownloadIcon color="white" />}
								>
									DK Data
								</TextButton>
							)} */}
							{user && user.isSuper && (
								<TextButton
									onClick={() =>
										ExportExcel(
											exportMembers,
											[
												"sno.",
												"Fullname",
												"Gender",
												"Age",
												"Country Code",
												"Mobile No.",
												"Passcode",
												"Email",
												"Residential Address",
												"State",
												"Country",
												"Pin/Zip Code",
												"Main Group Number",
												"Account Type",
												"Created On",
											],
											[
												"fullname",
												"gender",
												"age",
												"country_code",
												"mob_no",
												"password",
												"email",
												"address",
												"state",
												"country",
												"pin_code",
												"group_no",
												"is_admin",
												"created_on",
											],
											`ATM Members List as on ${dateFormat(
												new Date(),
												"dd/mm/yyyy"
											)}`
										)
									}
									startIcon={<DownloadIcon color="white" />}
								>
									Members Data
								</TextButton>
							)}

							{/* Payments Data Download */}
							{user && user.isSuper && (
								<TextButton
									onClick={() =>
										ExportExcel(
											payments,
											[
												"sno.",
												"Date & Time of Payment",
												"Name of the Donor",
												"Mobile Number",
												"Email Address",
												"Flat No./Door No./Building Name",
												"Street Name",
												"Area/District",
												"City",
												"State",
												"Pincode",
												"PAN",
												"Donation Activity",
												"Donation Amount",
												"Total Amount Paid",
												"Remarks",
												"Payment id",
												"Receipt Number",
												"Receipt Download",
												"Razorpay_pyt_signature",
											],
											[
												"created_on",
												"name",
												"mob_no",
												"email",
												"flat_no",
												"street",
												"area",
												"city",
												"state",
												"pin_code",
												"pan",
												"towards",
												"amount",
												"final_amount",
												"remarks",
												"payment_id",
												"receipt_no",
												"receipt_download_link",
												"rzpy_signature",
											],
											`ATM Donations Data as on ${dateFormat(
												new Date(),
												"dd/mm/yyyy"
											)}`
										)
									}
									startIcon={<DownloadIcon color="white" />}
								>
									Donations Data
								</TextButton>
							)}

							{user && user.isSuper && (
								<TextButton
									onClick={() => setOpenDel(true)}
									startIcon={<DeleteForever color="tomato" />}
									sx={{
										color: "tomato",
									}}
								>
									Delete Member
								</TextButton>
							)}
							<TextButton
								startIcon={<LanguageIcon color="white" />}
								onClick={() => {
									navigate("/");
								}}
							>
								Main website
							</TextButton>
							<TextButton
								startIcon={<KeyboardBackspace color="white" />}
								onClick={() => {
									window.localStorage.removeItem("user");
									window.sessionStorage.removeItem("user");
									navigate("/");
								}}
							>
								Logout
							</TextButton>
						</div>
					</div>
					{loading ? (
						<div className="loading">
							<CircularProgress thickness={4} />
						</div>
					) : (
						<div className="admin-hero">
							<div className="admin-left">
								<div className="admin-right-head">
									<div className="admin-left-title">
										<h2>Announcements</h2>
										<Icon>
											<RepeatIcon htmlColor="orange" />
										</Icon>
									</div>
									{user && (user.anns || user.isSuper) && (
										<Tooltip title="Add Announcement">
											<IconButton onClick={() => setOpenAnns(true)}>
												<Icon>
													<Add htmlColor="orange" />
												</Icon>
											</IconButton>
										</Tooltip>
									)}
								</div>

								<div className="admin-right-content">
									{data_announcements.map((d) => (
										<Card user={user} key={d.id} data={d} />
									))}
								</div>
							</div>
							<div className="admin-right">
								<div className="admin-right-head">
									<div className="admin-right-title">
										<h2>Activities</h2>
										<Icon>
											<RepeatIcon htmlColor="orange" />
										</Icon>
									</div>
									{user && (user.acts || user.isSuper) && (
										<Tooltip title="Add Activity">
											<IconButton onClick={() => setOpenActs(true)}>
												<Icon>
													<Add htmlColor="orange" />
												</Icon>
											</IconButton>
										</Tooltip>
									)}
								</div>
								<div className="admin-right-content">
									{data_activities.map((d) => (
										<Card user={user} activities key={d.id} data={d} />
									))}
								</div>
							</div>
						</div>
					)}

					<Activities
						open={openActs}
						onClose={() => setOpenActs(false)}
						id={null}
					/>
					<Announcements
						open={openAnns}
						onClose={() => setOpenAnns(false)}
						id={null}
					/>

					<FixedActs
						members={members}
						data={vps_data}
						open={openVps}
						onClose={() => setOpenVps(false)}
						vps
					/>
					<FixedActs
						members={members}
						data={svs_data}
						open={openSvs}
						onClose={() => setOpenSvs(false)}
					/>

					<Dialog open={openDel} onClose={() => setOpenDel(false)}>
						<DialogTitle>Delete Member</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<strong>
									WARNING !!! All data relating to the member will be
									permanently deleted. The member will be immediately logged out
									and will not be able to login or access any features of the
									app unless they register again
								</strong>
							</DialogContentText>
							<DialogContent>
								{delLoading ? (
									<CircularProgress thickness={4} />
								) : (
									<TextInput
										placeholder="Enter Mobile No."
										type="text"
										label="Mobile Number"
										color="success"
										onChange={(e) => setDelMob(e.target.value)}
									/>
								)}
							</DialogContent>
						</DialogContent>
						<DialogActions>
							<FilledButton
								disabled={!delMob || delLoading}
								onClick={DelMember}
								startIcon={<DeleteForever />}
							>
								Confirm Delete
							</FilledButton>
							<TextButton onClick={() => setOpenDel(false)}>Cancel</TextButton>
						</DialogActions>
					</Dialog>
				</>
			)}
		</div>
	);
}
