import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../shared/utils/Navbar";
import Activities from "../Activities";

export default function ActivitiesRoute() {
	return (
		<div>
			<>
				<Helmet>
					<title>Activities</title>
					<meta
						name="description"
						content="ATM Group initiatives are being managed by Professionals with tireless and dedicated efforts ..... "
					/>
					<link rel="canonical" href="/activities" />
				</Helmet>
				<Navbar active="activities" />
				<Activities />
			</>
		</div>
	);
}
