import React from "react";
import { useSelector } from "react-redux";
import * as DOMPurify from "dompurify";

/* Importing styles */
import "../styles/activities.css";

export default function Activities() {
	const activities = useSelector(
		(state) => state.reducer.data.website.activities
	);
	return (
		<section className="activities" id="activities">
			<div className="act-blob"></div>
			<div className="act-hero">
				<div className="act-left">
					<div className="act-hero-head">
						<img
							width="500"
							loading="lazy"
							height="250"
							title="ATM Global Group"
							src="./assets/icons/activities_icon.svg"
							alt=""
						/>
						<h1>OUR ACTIVITIES</h1>
					</div>
					<div className="act-hero-text">
						<span
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(activities),
							}}
							style={{
								textJustify: "inter-word",
								whiteSpace: "pre-line",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								color: "#e3651d",
							}}
						></span>
					</div>
				</div>
				<div className="act-right">
					<img
						width="500"
						loading="lazy"
						height="250"
						title="ATM Global Group"
						src="./assets/images/atm_final_2.webp"
						alt=""
					/>
				</div>
			</div>
		</section>
	);
}
