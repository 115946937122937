import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../shared/utils/Navbar";
import JoinUs from "../JoinUs";

export default function JoinRoute() {
	return (
		<div>
			<>
				<Helmet>
					<title>Join Us</title>
					<meta
						name="description"
						content='To Join the group and be part of our initiatives,
									WhatsApp"ATM" to +8613472673759'
					/>
					<link rel="canonical" href="/join" />
				</Helmet>
				<Navbar active="join" />
				<JoinUs />
			</>
		</div>
	);
}
